import React from "react";

const Footer = () => { 
	return ( 
		<footer>
			<p className="footer">Copyright © {(new Date().getFullYear())} - All Rights Reserved - Saima Khanom </p>
		</footer>
	); 
};

export default Footer;