import React from "react";
import Questions from "./Questions";

const RadioForm = () => {
    
	return (
		<div>
            
			<Questions/>
            
		</div>
	);
};

export default RadioForm;